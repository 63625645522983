<script>
export default {
  name: "LangSwitcher",

  data: () => ({
    show: false,
    items: [
      {
        icon: require("../../assets/images/locales/ro.svg"),
        text: "Română",
        value: "ro",
      },
      {
        icon: require("../../assets/images/locales/ru.svg"),
        text: "Русский",
        value: "ru",
      },
      {
        icon: require("../../assets/images/locales/us.png"),
        text: "English",
        value: "en",
      },
    ],
  }),

  computed: {
    lang: {
      set({ value }) {
        this.$store.dispatch("localization/set", value);
        const currentUrl = location.href.split("/");
        const langPosition = 3;

        currentUrl[langPosition] = value;
        location.href = currentUrl.join("/");
      },
      get() {
        return this.$store.getters["localization/getCurrent"];
      },
    },
    icon() {
      return this.items.find((item) => item.value === this.lang).icon;
    },
  },

  methods: {
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<template>
  <v-menu offset-y nudge-bottom="20" v-model="show">
    <template #activator="{ on, attrs }">
      <div class="nav-button d-flex align-center pr-1" v-on="on" v-bind="attrs">
        <v-img width="28px" :src="icon"></v-img>
        <v-icon class="ml-1" color="innirm"> mdi-chevron-down </v-icon>
      </div>
    </template>
    <v-list dense class="py-0">
      <v-list-item-group v-model="lang" color="primary">
        <v-list-item v-for="(item, index) in items" :key="index" :value="item">
          <v-list-item-icon>
            <v-img width="20px" :src="item.icon"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<style lang="scss" scoped>
.theme-settings__open-button {
  margin-top: 192px;
}
</style>
