<script>
import LangSwitcher from "../settings/LangSwitcher";

export default {
  name: "NavBar",

  components: {
    LangSwitcher,
  },

  data: () => ({
    dialog: false,
    expand: false,

    logoImage: require("@/assets/images/logos/logo-icon.svg"),
    scbLogoImage: require("@/assets/scb-logo.png"),
    logoText: require("@/assets/images/logos/logo-text.svg"),

    header_items: [
      {
        text: "about",
        type: "span",
        goTo: "#about",
      },
      {
        text: "services",
        type: "span",
        goTo: "#services",
      },
      {
        text: "specialists",
        type: "span",
        goTo: "#specialists",
      },
      {
        text: "for-patients",
        type: "menu",
        content: [
          {
            text: "rmn",
            goTo: "/about",
          },
          {
            text: "patients-guide",
            goTo: "/about#patients-guide",
          },
          {
            text: "how-to-get-appointment",
            goTo: "/about#how-to-make-an-appointment",
          },
          {
            text: "terms",
            goTo: "/terms",
          },
          {
            text: "confidentiality",
            goTo: "/privacy",
          },
          {
            text: "cabinet",
            goTo: "/auth/login",
            disabled: true,
          },
          {
            text: "faq",
            disabled: true,
          },
        ],
      },
      {
        text: "contact",
        type: "span",
        goTo: "#contact",
      },
    ],

    scrolled: false,
  }),

  mounted() {
    this.scroll();
  },

  computed: {
    isHomePageRoute() {
      return this.$route.meta.module === "home";
    },
    isLoginPageRoute() {
      let userCabinetPages = ["auth", "profile", "confirm"];
      return userCabinetPages.includes(this.$route.meta.module);
    },
  },

  methods: {
    scroll() {
      window.addEventListener("scroll", () => {
        const top = window.pageYOffset;

        this.scrolled = top > 250;
      });
    },
    goTo(destination) {
      this.dialog = false;
      this.$vuetify.goTo(destination);
    },
    goHome() {
      if (this.isLoginPageRoute) {
        return (document.location.href = "https://innirm.md");
      }

      if (this.isHomePageRoute) {
        this.$vuetify.goTo(0);
      } else {
        this.$router.push("/home");
      }
    },
    openScb() {
      document.location.href = "https://scbirm.md";
    },
  },
};
</script>

<template>
  <v-app-bar
    app
    :class="{ 'mt-1': $vuetify.breakpoint.mdAndDown }"
    class="elevation-0 pa-0"
    min-height="60"
    height="60"
  >
    <v-row class="white pt-3 pb-3 elevation-3">
      <v-expand-transition>
        <v-col cols="12" class="innirm py-0" v-show="!scrolled">
          <v-container class="px-sm-8">
            <v-row class="mt-7 pb-2">
              <v-col
                cols="6"
                class="d-none d-md-flex align-center white--text py-0"
              >
                <v-icon size="18" color="white"> mdi-clock-outline</v-icon>
                <span class="pl-2"> {{ $t("nav.time") }}, 24/7 </span>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="d-flex justify-center justify-md-end align-center white--text py-0"
              >
                <v-icon size="18" color="white"> mdi-cellphone</v-icon>
                <span class="pl-2">
                  INNIRM Chișinău +373 22 8080 20, SCBIRM Bălți +373 22 8080 21
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-expand-transition>
      <v-col cols="12" class="py-0">
        <v-container class="px-sm-8 align-end">
          <v-row>
            <v-col cols="5" class="pt-2 pb-0 pt-3 d-flex align-end">
              <div
                class="d-flex justify-center align-center white--text cursor-pointer"
              >
                <div class="d-flex justify-center align-center">
                  <div class="d-flex align-center pointer" @click="goHome">
                    <v-img width="36" :src="logoImage" />
                    <v-img
                      width="120"
                      height="35"
                      :src="logoText"
                      class="ml-2"
                    />
                  </div>
                </div>
                <div v-if="isLoginPageRoute" class="d-flex justify-center align-end  ">
                  <div class="d-flex align-center pointer" @click="openScb()">
                    <img
                      class="ml-15 scb-logo"
                      width="150"
                      :src="scbLogoImage"
                    />
                  </div>
                </div>
              </div>
            </v-col>
            <v-col
              cols="7"
              class="pr-2 pb-1 pt-4 d-flex justify-end align-center"
            >
              <div class="d-none d-sm-flex">
                <lang-switcher />
              </div>
              <!-- PC -->
              <div
                class="d-flex"
                v-if="!$vuetify.breakpoint.mdAndDown && isHomePageRoute"
              >
                <div
                  class="d-flex"
                  v-for="(item, index) in header_items"
                  :key="index"
                >
                  <v-btn
                    v-if="item.type === 'span'"
                    text
                    class="px-1"
                    @click="goTo(item.goTo)"
                  >
                    <span
                      class="text-no-wrap hoverable text-caption text-uppercase"
                    >
                      {{ $t(`home.header.${item.text}`) }}
                    </span>
                  </v-btn>
                  <v-menu
                    v-else-if="item.type === 'menu'"
                    open-on-hover
                    bottom
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="d-flex align-center text-no-wrap hoverable px-1"
                      >
                        <span
                          class="text-caption text-uppercase"
                          :class="{ disabled: item.disabled }"
                        >
                          {{ $t(`home.header.${item.text}.text`) }}
                        </span>
                        <v-icon size="18"> mdi-chevron-down</v-icon>
                      </div>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(children, index) in item.content"
                        :key="index"
                        class="px-0"
                      >
                        <v-list-item-content class="py-0">
                          <v-btn
                            link
                            text
                            block
                            :to="children.goTo"
                            class="d-flex justify-start hoverable"
                            style="height: 48px"
                            :class="{ disabled: children.disabled }"
                          >
                            <span
                              class="text-no-wrap text-caption text-uppercase pr-5 pr-md-3"
                            >
                              <v-icon color="innirm">
                                mdi-chevron-right
                              </v-icon>
                              {{
                                $t(
                                  `home.header.${item.text}.content.${children.text}`
                                )
                              }}
                            </span>
                          </v-btn>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <!-- Tablet -->
              <div
                class="text-center"
                v-if="
                  $vuetify.breakpoint.mdAndDown &&
                  !$vuetify.breakpoint.xs &&
                  isHomePageRoute
                "
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="innirm"
                      class="mr-3"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="custom-list">
                    <v-list-item
                      v-for="(item, index) in header_items"
                      :key="index"
                      class="hoverable px-0"
                    >
                      <v-list-item-content class="py-0">
                        <v-btn
                          text
                          block
                          class="d-flex justify-start hoverable fixed-height"
                          v-if="item.type === 'span'"
                          @click="goTo(item.goTo)"
                        >
                          <span
                            class="text-no-wrap text-caption text-uppercase pr-5 pr-md-3"
                          >
                            <v-icon color="innirm"> mdi-chevron-right </v-icon>
                            {{ $t(`home.header.${item.text}`) }}
                          </span>
                        </v-btn>
                        <v-menu
                          offset-x
                          v-else-if="item.type === 'menu'"
                          open-on-click
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              text
                              block
                              class="d-flex justify-start hoverable"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="innirm">
                                mdi-chevron-right
                              </v-icon>
                              <span class="text-caption text-uppercase">
                                {{ $t(`home.header.${item.text}.text`) }}
                              </span>
                              <v-icon class="ml-1"> mdi-chevron-right</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(children, index) in item.content"
                              :key="index"
                              class="px-0"
                            >
                              <v-btn
                                block
                                class="d-flex justify-start hoverable"
                                style="height: 48px"
                                text
                                link
                                :to="children.goTo"
                                :class="{ disabled: children.disabled }"
                              >
                                <span
                                  class="text-no-wrap hoverable text-caption text-uppercase pr-5 pr-md-3"
                                >
                                  {{
                                    $t(
                                      `home.header.${item.text}.content.${children.text}`
                                    )
                                  }}
                                </span>
                              </v-btn>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <!-- Phone -->
              <v-dialog
                fullscreen
                v-model="dialog"
                v-if="$vuetify.breakpoint.xs && isHomePageRoute"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    color="innirm"
                    class="mr-3"
                  >
                    <v-icon> mdi-menu</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline grey lighten-2">
                    <span> Menu </span>
                    <v-spacer />
                    <v-icon
                      @mouseup="dialog = false"
                      class="innirm rounded white--text"
                    >
                      mdi-close
                    </v-icon>
                  </v-card-title>
                  <v-card-text class="px-0">
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in header_items"
                        :key="index"
                        class="px-0"
                      >
                        <v-btn
                          v-if="item.type === 'span'"
                          block
                          text
                          depressed
                          style="height: 48px"
                          @click="goTo(item.goTo)"
                          class="hoverable text-uppercase d-flex justify-start"
                        >
                          <span>
                            <v-icon color="innirm" style="width: 24px">
                              mdi-chevron-right
                            </v-icon>
                            {{ $t(`home.header.${item.text}`) }}
                          </span>
                        </v-btn>
                        <div v-else-if="item.type === 'menu'">
                          <v-btn
                            @click="expand = !expand"
                            class="d-flex justify-start hoverable align-center"
                            style="height: 48px"
                            text
                            block
                            depressed
                          >
                            <v-icon color="innirm" style="width: 24px">
                              mdi-chevron-right
                            </v-icon>
                            <span class="ml-1">
                              {{ $t(`home.header.${item.text}.text`) }}
                            </span>
                            <v-icon> mdi-chevron-down</v-icon>
                          </v-btn>
                          <div>
                            <v-expand-transition>
                              <v-card v-show="expand" class="ml-5">
                                <v-card-text class="pa-0">
                                  <v-list>
                                    <v-list-item
                                      v-for="(children, index) in item.content"
                                      :key="index"
                                      class="px-0"
                                    >
                                      <v-btn
                                        block
                                        class="d-flex justify-start hoverable"
                                        style="height: 48px"
                                        text
                                        link
                                        :to="children.goTo"
                                        :class="{ disabled: children.disabled }"
                                      >
                                        <span
                                          class="text-no-wrap hoverable text-caption text-uppercase pr-5 pr-md-3"
                                        >
                                          {{
                                            $t(
                                              `home.header.${item.text}.content.${children.text}`
                                            )
                                          }}
                                        </span>
                                      </v-btn>
                                    </v-list-item>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-expand-transition>
                          </div>
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <lang-switcher />
                    <v-spacer></v-spacer>
                    <v-btn color="innirm" depressed class="white--text">
                      <span> {{ $t("global_buttons.appointment-btn") }} </span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <div class="div" v-if="isLoginPageRoute === false">
                <v-btn
                  v-if="!isHomePageRoute && $vuetify.breakpoint.xs"
                  depressed
                  color="innirm"
                  link
                  to="/home#appointment"
                  class="text-body-2 white--text"
                >
                  <span> {{ $t("global_buttons.appointment-btn") }} 1 </span>
                </v-btn>
                <v-btn
                  v-if="!isHomePageRoute"
                  depressed
                  color="innirm"
                  link
                  to="/home#appointment"
                  class="d-none text-body-2 d-sm-flex white--text"
                >
                  <span> {{ $t("global_buttons.appointment-btn") }} 1 </span>
                </v-btn>
                <v-btn
                  v-else
                  depressed
                  color="innirm"
                  @mouseup="$vuetify.goTo('#appointment')"
                  class="d-none text-body-2 d-sm-flex white--text ml-1"
                >
                  <span> {{ $t("global_buttons.appointment-btn") }} </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<style lang="scss" scoped>
.v-app-bar {
  button {
    transition: 0.3s;

    &:hover {
      color: var(--v-innirm-base);
    }
  }

  span.hoverable {
    font-size: 13px;
    white-space: nowrap;
    transition: 0.3s;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: -1px;
  }

  .v-select {
    &::v-deep {
      max-width: 135px;

      .v-input__slot {
        padding: 0 0 0 8px !important;
        background: transparent;
        box-shadow: none;

        .v-label {
          color: #000000de;
          font-weight: 500;
          font-size: 14px;
          font-family: "Roboto", sans-serif;
          line-height: 1.5;
        }
      }
    }
  }

  .v-list {
    &::v-deep .v-list-item {
      min-height: 40px;

      &:hover {
        color: red !important;
      }
    }
  }

  .underlined {
    width: 100% !important;
  }

  &::v-deep .v-toolbar__content {
    padding: 0;
    background: transparent !important;
  }
}

.headline {
  position: relative;
}

.headline:after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: -4px;
  height: 4px;
  width: 100%;
  background: var(--v-innirm-base);
}

.scb-logo .v-image__image {
  height: 10px !important;
  width: 30px !important;
}
.custom-list {
  &::v-deep {
    .v-list-item {
      span {
        transition: 0.3s;
      }

      &:hover {
        span {
          color: var(--v-innirm-base);
        }
      }
    }
  }
}

.hoverable {
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    color: var(--v-innirm-base) !important;
  }
}

.pointer {
  cursor: pointer;
}

.fixed-height {
  min-height: 48px !important;
}

.v-list::v-deep {
  button.disabled {
    cursor: default;

    .v-icon {
      display: none;
    }

    span {
      color: gray !important;
    }
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
</style>
