<script>
import NavBar from "@/components/navigation/NavBar";
import Alert from "@/components/dialogs/Alert.vue";

export default {
  name: "Default",

  components: { Alert, NavBar },
};
</script>

<template>
  <div class="fill-height">
    <nav-bar></nav-bar>
    <v-main class="fill-height">
      <router-view class="pa-0 pt-1" />
    </v-main>
    <alert></alert>
  </div>
</template>

<style lang="scss" scoped></style>
